<template>
	<div>
		<div class="home">
			<div id="liveEvents" class="home__container">
				<div style="margin-bottom:5vh; margin-top:20vh;">
					<!-- <h1>Quality, <em>dependable,</em> on-demand workers with <em>accountable,</em> on-site support</h1> -->
					<h1 class="pb-3">On-Demand Event Staff</h1>
					<p class="page-intro">Culinary, Catering, Concessions, Bartending, Guest Services, Grounds Crew, Parking, and More.</p>
					<div data-aos="fade-in" data-aos-delay="1000">
						<a href="#" v-scroll-to="{el: '#scrollHere', offset: -60, duration: 400}">
							<button class="btn btn__ghost btn__mega mt-5 mr-5">Learn More</button>
						</a>
						<button class="btn btn__primary btn__mega mt-5" @click="rModal()">Get Pricing</button>
					</div>
				</div>
			</div>
			<div class="whiteBlock" id="scrollHere">
				<div class="pageBlock align-center flex-column pb-0 text-center" id="element1">
					<h2 class="pb-5">Over a Decade of Staffing and Operating Large Events</h2>
					<p>Music Festivals, Concerts, Professional Sports... it takes a lot of people and expertise to bring large events to life. We bring ~60,000+ workers and over a decade of experience staffing hundreds of major events to the table. Our local teams can staff all food and beverage positions, all guest services positions, and even provide general labor.</p>
					<p>We aren't just another staffing company. Live events are our passion. Let us prove it to you.</p>
				</div>
			</div>
			<!-- <div class="greyBlock">
				<div class="pageBlock" id="element">
					<div class="pa-5">
						<em><h1>We are </h1></em>
					</div>
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-phone-volume fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Call-Offs</h4>
						<p>Call-offs happen and you can't afford to be short-staffed on important jobs. Our local teams are ready to jump in and help, even on short notice.</p>
					</div>
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-football fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Seasonal Reinforcements</h4>
						<p>You may have larger jobs or seasonal jobs where it's just not practical to onboard and manage your own employees. We have the bandwidth to make sure your roster is full.</p>
					</div>
					<div class="pageBlock__col-1-3 pa-5 flex-column align-center text-center">
						<span><i class="fa-duotone fa-people-group fa-4x primaryDark pt-3 pb-5"></i></span>
						<h4 class="mb-3">Regular Daily Workforce</h4>
						<p>Janitors are in high-demand and most companies could use at least a few more of them every single day. We're ready to jump in.</p>
					</div>
				</div>
			</div> -->
			<div class="whiteBlock">
				<div class="pageBlock pt-5 pb-5 mb-5" id="element2">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/homepage%2F1641745009_998071_1641745083_noticia_normal.jpeg?alt=media&token=98bd87f9-7922-460a-a546-842e1e4389a0" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Temp Staffing for Sports Venues</h2>
							<h4 class="mb-3">We know our way around stadiums and arenas</h4>
							<p>Whether you need 20 workers for a conversion crew, 75 ticket-takers, or 400 concessions stand attendants, we've got you covered.</p>
							<p>Unlike other companies that just send workers and hope for the best, we bring a full team to check-in, place, manage, and clock-out our staff. We have years of experience with professional football, baseball, soccer, basketball and hockey, as well as all college sports.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element3">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Amphitheater Staffing</h2>
							<h4 class="mb-3">Concert Season is the Best Season</h4>
							<p>We've staffing amphitheaters from coast to coast and have teams in place to make sure your concert is fully-staffed.</p>
							<p>Typical positions include bartenders, beer-pourers, barbacks, prep cooks, and food runners. Whether you need 5 extra hands or 150 with on-site management, we've done it before and are ready to step up and get the job done right.</p>
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/FYnDb8cX0AUElEK.jpeg?alt=media&token=79e7d9a1-f13d-4f83-a254-e40216c1cac5" alt="">
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5" id="element4">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/Screen%20Shot%202022-09-06%20at%208.15.12%20PM.png?alt=media&token=31c1a381-b7ad-496e-97f2-89c6679056d0" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Guest Services Staffing</h2>
							<h4 class="mb-3">Ticket-takers, Ushers, and More</h4>
							<p>Where a concessonnaire might oversee the food and beverage operations, guest services often falls on the venue operator or sports team. Recruiting and managing guest services workers isn't easy and more and more customers are outsourcing to staffing companies.</p>
							<p>Our guest services teams are trained to interact with your guests and we can even require that they watch your training content to fully understand your brand and culture. We can handle all clocking-in and out, equimpent, and returning uniforms, taking pressure off of your in-house staff.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element5">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">VIP and Premium Staff</h2>
							<h4 class="mb-3">You can trust us with your most important guests</h4>
							<p>VIP lounges at concert venues, artist suites and service, and VIP servers at sporting events are just a few examples of the high-end positions we handle.</p>
							<p>If you're looking for high-end staff, we bring the best of the best, plus on-site leads and/or managers to make sure everything is perfect.</p>
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/Screen%20Shot%202022-09-06%20at%207.26.01%20PM-min.png?alt=media&token=be80ecc8-1c30-4d65-871d-cf7c1ef55c5b" alt="">
					</div>
				</div>
				
				<div class="pageBlock pt-5 pb-5 mb-5" id="element6">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/bonnaroo-glory-arch-press-2018-crop.jpeg?alt=media&token=ea87bb2b-a6a8-4e3a-8327-5a61b4a1f5ce" alt="">
					</div>
					<div class="pageBlock__col-1-text">
					<div>
							<h2 class="mb-2">Music Festival Experts</h2>
							<h4 class="mb-3">The Best in the Business</h4>
							<p>Our founders started selling water at Coachella over a decade ago and have since staffed 100+ of the biggest music festivals.</p>
							<p>Music festival services include bartenders, beer pourers, barbacks, cashiers, cooks, ushers, ticket-takers, grounds crew, and guest services.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element7">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Temp Staffing for Arenas</h2>
							<h4 class="mb-3">Supporting Just About All Arena Positions</h4>
							<p>Busy arenas need lots of regular staff to operate at a high level. In many markets, arena operators and concessionnaires are struggling to be full. Many are relying on temp staff and constantly battling no-shows.</p>
							<p>We treat venue operators like our best customers and give you the attention and effort you deserve. We deliver fully-staffed events, on-site leads and managers, and act as true partners during events.</p>
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/1200x0.jpeg?alt=media&token=d1978b0d-8194-429e-a008-c74b5f7b218d" alt="">
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5" id="element4">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/220507220710-07-kentucky-derby-winner-min.jpeg?alt=media&token=c977c7fe-eb6c-4e74-b1fd-45adf9108355" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Bucket-List Events</h2>
							<h4 class="mb-3">From Super Bowls to Award Shows and the Kentucky Derby</h4>
							<p>Your company has a handful of bucket-list events on the calendar. Whether it's the Academy Awards, College Football Playoffs, March Madness, or PGA Major, we've been there before. We know how to recruit, screen, and staff these flagship events.</p>
							<p>Often times there are additional complications like extended background checks, huge blocks of rooms to rent, or additional security measures... we've been through all of it. We have the experience, team, tech, and infrastructure to successfully staff any complex event in the country.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element5">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">On-Site Staffing Coordination</h2>
							<h4 class="mb-3">Off to the Races</h4>
							<p>Some events, like car races, require huge numbers of staff, spread out over miles and miles. These events require expertise and extra communication and coordination with you, the client.</p>
							<p>These are the events where it's critical to work with a staffing company that knows what they are doing. More than that, our company philosophy is that we are your partners. We don't rest until you rest. And, if there is more that our team can do to help, we do it.</p>
							<!-- <p>Room service takes longer. Food trays sit in hallways. Morale and quality suffer as your staff has to do more with less. This is where extra professionals can help - on busy days or every day.</p> -->
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/formula-1-united-states-gp-202-2-min.jpeg?alt=media&token=98913cb2-606d-41a3-b95c-81fb248d4ea2" alt="">
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5" id="element6">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/coors-main.jpeg?alt=media&token=7e8ac088-81fd-42e4-b39f-9a000b297f8e" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Temp Staffing for Baseball</h2>
							<h4 class="mb-3">Take Us Out To The Ballpark</h4>
							<p>Baseball season is the best season in sports, and some of the best events to utilize quality temp staff. The season is long with ups and downs and staff turnover. We can work with you as a partner to keep you fully-staffed all year.</p>
							<p>We typically work with concessionnaires to provide concessions stand attendants, cashiers, cooks, and dishwashers. Some ballparks also need help with suite attendants and catering. We work the stadiums and/or teams to provide ushers, ticket-takers, and guest services support.</p>
						</div>
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5 reverse" id="element5">
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Staffing at Golf Tournaments</h2>
							<h4 class="mb-3">Special Staff for Special Events</h4>
							<p>Golf tournaments require huge numbers of staff and management and we are one of the most experienced companies in the country.</p>
							<p>We can bring hundreds of barteners, chefs, prep cooks, servers, and suite attendants to any golf tournament in the country.</p>
						</div>
					</div>
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/skysports-the-memorial-muirfield-village_5004847.jpeg?alt=media&token=313c6476-afdf-4e9c-9f0a-13bcdc955958" alt="">
					</div>
				</div>
				<div class="pageBlock pt-5 pb-5 mb-5" id="element6">
					<div class="pageBlock__col-1">
						<img data-aos="fade-in" src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/d23expo_2019_anaheimcourtesyap_09062022.jpeg?alt=media&token=8b0bd554-1653-4ee8-b5a6-df6aaa1cfaae" alt="">
					</div>
					<div class="pageBlock__col-1-text">
						<div>
							<h2 class="mb-2">Convention Center Staffing</h2>
							<h4 class="mb-3">Presenting You Professionally</h4>
							<p>Convention Centers across the country are getting busier as every event draws more in-person attendees. We can help make sure you have enough workers to support them.</p>
							<p>We commonly provide food and beverage staff, including barteners, cooks, catering staff, and buffet staff. We also provide guest services, ticket-takers, and even promo models to exhibitors.</p>
						</div>
					</div>
				</div>
			</div>






			

				<div class="whyHire" id="checklist">
					<h2 class="montserrat">Why Partner With <span>J<em>u</em>mp</span> for Live Events?</h2>
					<div class="checklist__wrapper">
						<div class="checklist--item">
							<div class="i-border">
								<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="300"></i>
							</div>
							<p class="new"><span class="strong">60,000+ Workers</span> - We have over 60,000 workers nationwide, with the capacity to handle just about any request in any city.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="600"></i>
								</div>
								<p class="new"><span class="strong">Years of Experience</span> - With over a decade and hundreds of large events under our belt, there's nothing we don't know how to handle.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="600"></i>
								</div>
								<p class="new"><span class="strong">Customized Training Modules</span> - Do you or your customers have specific training requirements? We can add that training to our onboarding process.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="900"></i>
								</div>
								<p class="new"><span class="strong">Dedicated Account Support</span> - You'll work with a dedicated team to ensure that every shift is full and correct, and ever job is done right.</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1200"></i>
								</div>
								<p class="new"><span class="strong">Shift Management</span> - We create your shifts, manage applications, and handle last-minute changes. We don't expect you to create and staff your own shifts!</p>
							</div>
							<div class="checklist--item">
								<div class="i-border">
									<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1500"></i>
								</div>
								<p class="new"><span class="strong">Client Dashboard</span> - Login for easy access to your orders, your staff, and to plan for future shifts.</p>
							</div>
						</div>
					</div>
				</div>

				<div class="backLightPrimary">
					<div class="pageBlock align-center flex-column pt-5 pb-5 text-center" >
						<h2 style="color:white;" class="pb-5">Get Pricing and More Information About Our Local Workforce</h2>
						<button class="btn btn__primaryDark btn__large" @click="rModal()">Get Started <i class="fa-duotone fa-arrow-right ml-3"></i></button>

						<transition name="modal">
							<QuoteRequest v-if="showQuote" @close="closeModal()" />
						</transition>
					</div>
				</div>


		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import Jumpers from '@/components/Jumpers.vue'
import WorkerBenefits from '@/components/WorkerBenefits.vue'
import QuoteRequest from '@/components/QuoteRequest.vue'
import VenueTypes from '@/components/VenueTypes.vue'

export default {
	name: 'live-events',
	data() {
    return {
    	showQuote: false,
    	showPricing: false,
    	showInsurance: false,
    	showScreen: false,
    	showManage: false,
    	showDifferent: false,
    }
  },
	components: {
		Jumpers,
		WorkerBenefits,
		VenueTypes,
		QuoteRequest
	},
	methods: {
		rModal() {
      this.showQuote = true
    },
    closeModal() {
      this.showQuote = false
    },
	},
	metaInfo: () => ({
    title: 'Jump',
    titleTemplate: '%s | Live Event Staffing Platform',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Temp Staff For Your Live Events',
    }],
  })
}
</script>